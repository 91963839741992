import Popup from 'reactjs-popup';
import styled, { css } from 'styled-components';
import { SM } from '@/constants';

const Burger: React.FC<{
  className: any;
  onClick: any;
}> = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} viewBox="270 220 400 200">
    <path
      d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
      id="top"
    ></path>
    <path d="M300,320 L540,320" id="middle"></path>
    <path
      d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
      id="bottom"
      transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
    ></path>
  </svg>
);

export const BurgerMenu = styled(Burger)<{
  readonly sideBarActive?: any;
}>`
  display: none;
  @media (max-width: 48em) {
    display: block;
  }
  width: 40px;
  height: 40px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s
        cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s
        cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 40px;
    stroke-linecap: round;
    stroke: ${({ theme }) => theme.colors.offBlack};
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &.cross path {
    stroke: ${({ theme }) => theme.colors.blue};
  }
`;

export const DIV = styled.div`
  :before {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(0, 119, 193, 0.2) 0%,
      rgba(0, 119, 193, 0) 100%
    );
    z-index: -1;
    height: 322px;
    position: absolute;
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    :before {
      height: 197px;
    }
  }
`;

export const FlexIt = styled.div`
  display: flex;
  align-items: center;
`;
export const Wrapper = styled.header<{ isOverflowVisible?: boolean }>`
  overflow-x: ${({ isOverflowVisible }) =>
    isOverflowVisible ? 'visible' : 'unset'};
  width: 100%;
  z-index: 3;
`;
export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  padding: 20px 25px 0px 25px;

  @media (min-width: 48em) and (max-width: 75em) {
    width: 100%;
  }

  @media (min-width: 75em) and (max-width: 102.5em) {
    width: 100%;
  }

  @media only screen and (max-width: 48em) {
    width: 100%;
    height: auto;
  }
`;
export const StyledLink = styled.a`
  display: block;
  cursor: pointer;
  z-index: 99;
`;
export const StyledImg = styled.img`
  display: block;
  min-width: 55px;
  max-width: 55px;
  object-fit: contain;
  margin: auto;
`;
export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 48em) {
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
  }
`;
export const SwitchLang = styled.button<{
  readonly isLanding?: boolean;
  readonly lang?: string;
}>`
  width: 96px;
  height: 40px;
  background-color: #fff;
  outline: 0;
  border: 0;
  display: block;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-right: ${({ isLanding }) => (isLanding ? '0' : '14px')};
  z-index: 99;

  color: #0479c1;
  font-size: 16px;
  line-height: 14px;

  @media (max-width: ${SM}) {
    width: ${({ isLanding }) => (isLanding ? '80px' : 'fit-content')};
    margin-right: ${({ isLanding }) => (isLanding ? '0' : '8px')};
  }

  ${({ lang }) =>
    lang &&
    css`
      font-family: ${lang === 'en' ? 'Tajawal, Lato' : 'Lato, Tajawal'};
    `}
`;
export const UserDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: auto;
  background: red;
  padding: 15px 10px;
  opacity: 0;
  visibility: none;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: translateY(30px);
  z-index: 100;
  box-shadow: ${({ theme }) => theme.colors.navbar.dropdownShadow};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  &::before {
    bottom: 100%;
    right: 18px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::before {
    border-color: transparent;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }
`;
export const StyledUser = styled.div`
  padding: 2px 10px 2px 2px;
  background: rgba(8, 120, 190, 0.1);
  border-radius: 10px;
  transition-duration: 0.5s;
  :hover {
    background: #a5d6f5;
    transition-duration: 0.5s;
  }
  .profile-avtar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
  padding-right: 3px;
  .arrow-down {
    margin-right: 10px;
    transition: 0.4s ease border-color;
    border-color: #000000;
    width: 7.92px;
    height: 4.58px;
  }
  @media only screen and (max-width: 48em) {
    margin-top: 0px;
  }
`;
export const StyledUserWrapper = styled.div<{
  readonly dropdownOpen?: boolean;
}>`
  position: relative;
  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      ${UserDropdown} {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(10px);
      }
      .arrow-down {
        border-color: ${({ theme: { colors } }) => colors.navbar.title};
      }
    `}
`;
export const Username = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  white-space: nowrap;
  margin: 0px 5px 0px 7px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${SM}) {
    max-width: 90px;
  }
`;
export const LogoutBtn = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.red};
  position: relative;
  transition: 0.3s ease padding 0.1s;
  &::before {
    content: '';
    pointer-events: none;
    height: 2px;
    width: 15px;
    background: ${({ theme }) => theme.colors.red};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-10px, -50%);
    opacity: 0;
    transition: 0.3s ease all;
  }
  &:hover {
    padding-left: 20px;
    &::before {
      opacity: 1;
      transform: translate(0, -50%);
      transition-delay: 0.2s;
    }
  }
`;
export const AddAccount = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  padding: 5px;
  transition: 0.3s ease background-color;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  &:hover {
    background-color: #eee;
  }
`;
export const AddIcon = styled.img`
  display: block;
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 5px;
  transform: translateY(2px);
`;
export const AddText = styled.span`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;
export const UsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
export const UserList = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  padding: 5px;
  transition: 0.3s ease background-color;
  border-radius: 4px;
  &:hover {
    background-color: #eee;
  }
`;
export const StyledPopup = styled(Popup)`
  &-overlay {
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
  }
  &-content {
    max-width: 95vw;
    max-height: 90vh;
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    height: auto;
    width: auto !important;
    @media (max-width: 575.98px) {
      width: 95% !important;
      padding: 40px 5px;
    }
  }
`;
export const LoginForm = styled.form`
  margin: 0 30px 40px;
  width: 340px;
  @media (max-width: 575.98px) {
    width: 90%;
    margin: 0 auto 30px;
  }
`;
export const LoginFormTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 30px;
  text-align: center;
  @media (max-width: 575.98px) {
    font-size: 14px;
    margin: 20px 10px;
  }
`;
