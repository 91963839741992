import { LG, MDB, XLGG, XLGGG, XMD, XSM, XXXL } from '@/constants';
import styled from 'styled-components';

export const Section = styled.div`
  margin-top: 80px;
  @media (max-width: ${MDB}) {
    margin-top: 80px;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding-right: 16px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${XSM}) {
    max-width: 560px;
    padding-right: 10px;
  }
  @media (min-width: ${MDB}) {
    max-width: 740px;
  }
  @media (min-width: ${XMD}) {
    max-width: 980px;
  }
  @media (min-width: ${LG}) {
    max-width: 1160px;
  }
  @media (min-width: ${XLGGG}) {
    max-width: 1280px;
  }
  @media (min-width: ${XXXL}) {
    max-width: 1800px;
  }
`;

export const StyledRow = styled.div`
  display: flex;

  @media (max-width: ${MDB}) {
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  color: #000;
  font-size: 4.3rem;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -1.76px;
  margin: 0;
  &:lang(ar) {
    padding-right: 50px;
  }
  @media (max-width: ${XLGG}) {
    font-size: 2.5rem;
    line-height: normal;
  }
  @media (max-width: ${MDB}) {
    font-size: 2rem;
    width: 266px;
    line-height: normal;
  }
  @media (min-width: ${XXXL}) {
    width: 80%;
    font-size: 4.8rem;
  }
`;
export const ImagesWrapper = styled.div<{
  readonly isRightSection?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: ${({ isRightSection }) => (isRightSection ? 'start' : 'center')};
  margin-top: ${({ isRightSection }) => (isRightSection ? '' : '60px')};

  @media (max-width: ${MDB}) {
    display: ${({ isRightSection }) => (isRightSection ? 'flex' : 'none')};
    margin-top: 20px;
    gap: 16px;
  }
  .margin {
    margin-top: 140px;
    @media (max-width: ${MDB}) {
      margin-top: 50px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

export const TextWrapper = styled.div`
  @media (max-width: ${MDB}) {
    padding-left: 9px;
    &:lang(ar) {
      padding-left: 12px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 16px;

  color: #0479c1;
  font-size: 1.5rem;
  line-height: 24px;
  letter-spacing: -0.24px;
  @media (max-width: ${MDB}) {
    margin-left: -3px;
  }
  div {
    svg {
      width: 30px;
      height: 30px;
      @media (min-width: ${XXXL}) {
        width: 45px;
        height: 45px;
      }
    }
  }
  @media (min-width: ${XXXL}) {
    font-size: 1.8rem;
  }
`;
