import styled from 'styled-components';

export const Wrapper = styled.main<{
  readonly fullWidth?: boolean;
}>`
  font-family: inherit;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 2/12;
`;
