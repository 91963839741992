import { Wrapper } from './DefaultLayout.styled';
import React, { ReactNode } from 'react';
import Footer from '../Footer/landingFooter';
import Navbar from '../Navbar/landingNavbar';

interface DefaultLayoutProps {
  children: ReactNode;
}

export default function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <>
      <Navbar />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </>
  );
}
