import Link from 'next/link';
import { LG, MD, SM, XLGGG, XXXL } from '@/constants';
import styled from 'styled-components';
import { SwitchLang } from '../Navbar.styled';

export const NavbarSticky = styled.nav`
  position: absolute;
  width: 84%;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;

  @media (max-width: ${MD}) {
    width: 90%;
    top: 32px;
  }

  @media (min-width: ${LG}) {
    max-width: 1160px;
  }
  @media (min-width: ${XLGGG}) {
    max-width: 1280px;
  }
  @media (min-width: ${XXXL}) {
    max-width: 1800px;
  }
`;

export const NavbarWrapper = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${XXXL}) {
    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

export const RLogo = styled(Link)`
  svg {
    width: 48px;
    height: 48px;
  }
`;

export const NavBarLinks = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  @media (max-width: ${SM}) {
    column-gap: 16px;
  }
`;

export const LoginButton = styled.button<{ readonly isLanding?: boolean }>`
  width: ${({ isLanding }) => (isLanding ? '164px' : '153px')};
  height: ${({ isLanding }) => (isLanding ? '48px' : '40px')};
  border-radius: 10px;
  background: #0479c1;
  border: none;
  cursor: pointer;

  color: #fff;
  font-weight: ${({ isLanding }) => (isLanding ? '600' : '500')};
  font-size: 1rem;

  transition: background 0.3s ease;

  :hover {
    background: #026cae;
  }
  @media (max-width: ${SM}) {
    width: ${({ isLanding }) => (isLanding ? '164px' : '137px')};
  }
  @media (min-width: ${XXXL}) {
    width: ${({ isLanding }) => (isLanding ? '198px' : '180px')};
    height: ${({ isLanding }) => (isLanding ? '64px' : '56px')};
    font-size: 1.3rem;
  }
`;
export const NavSwitchLang = styled(SwitchLang)`
  @media (min-width: ${XXXL}) {
    width: 120px;
    height: 56px;
    font-size: 1.3rem;
  }
`;
