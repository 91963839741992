import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import {
  Description,
  ImageContainer,
  ShareOfferWrapper,
  TextContainer,
  Title,
} from './ShareOffer.styled';
import { ReactSVG } from 'react-svg';
import { GetStartedButton } from '../yourUltimate/YourUltimate.styled';

interface Props {
  title: string;
  desc: string;
  image: string;
}

export default function ShareOffer({ title, desc, image }: Props) {
  const { t } = useTranslation('common');

  return (
    <ShareOfferWrapper>
      <ImageContainer>
        <img src={image} alt={title} loading="lazy" />
      </ImageContainer>

      <TextContainer>
        <Title>{title}</Title>
        <Description>{desc}</Description>

        <GetStartedButton>
          <Link href="/login">
            <span>{t('landing.get-started')}</span>
            <ReactSVG
              src="/static/assets/images/landing/social-icons/arrow.svg"
              title="Get Started"
            />
          </Link>
        </GetStartedButton>
      </TextContainer>
    </ShareOfferWrapper>
  );
}
