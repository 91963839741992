import styled from 'styled-components';
import { LG, MD, MDB, SM, XLGGG, XMD, XSM, XXXL } from '@/constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 24px auto;
  row-gap: 32px;

  @media (min-width: ${XSM}) {
    max-width: 560px;
  }
  @media (min-width: ${MDB}) {
    max-width: 740px;
    flex-direction: row;
    row-gap: 0;
    column-gap: 24px;
  }
  @media (min-width: ${XMD}) {
    max-width: 980px;
  }
  @media (min-width: ${LG}) {
    max-width: 1160px;
  }
  @media (min-width: ${XLGGG}) {
    max-width: 1280px;
  }
  @media (min-width: ${XXXL}) {
    max-width: 1800px;
  }
`;

export const ShareOfferWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background: #eaf4fa;
  padding: 64px 0;
`;
export const TextContainer = styled.div`
  width: 100%;
  padding: 0 56px;
  @media (max-width: ${LG}) {
    padding: 0 20px 0 30px;
  }
`;
export const Title = styled.h2`
  color: #000;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.33px;
  margin: 0;
  margin-top: 40px;

  @media (max-width: ${MDB}) {
    font-size: 1.75rem;
  }
  @media (min-width: ${XXXL}) {
    font-size: 2.7rem;
  }
`;

export const Description = styled.p`
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.18px;
  margin: 16px 0 32px;

  @media (max-width: ${LG}) {
    font-size: 0.8rem;
  }
  @media (max-width: ${MDB}) {
    font-size: 1rem;
  }
  @media (min-width: ${XXXL}) {
    font-size: 1.3rem;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
  @media (max-width: ${SM}) {
    padding: 0 24px;
  }
  @media (min-width: ${XXXL}) {
    padding: 0 60px;
  }
  img {
    width: 509px;
    height: 470px;
    @media (max-width: ${MD}) {
      width: 325px;
      height: 315px;
    }
    @media (min-width: ${MD}) and (max-width: ${LG}) {
      width: 90%;
      height: 315px;
    }
    @media (max-width: 340px) {
      width: 100%;
    }
    @media (min-width: ${XXXL}) {
      width: 100%;
      height: 100%;
    }
  }
`;
