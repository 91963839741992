import { LG, MD, MDB, SM, XLGGG, XMD, XSM, XXXL } from '@/constants';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  margin-top: 24px;
  grid-row: 2;
  grid-column: 2/12;
  padding-top: 4rem;
  background: linear-gradient(
    180deg,
    rgba(0, 119, 193, 0) 0%,
    rgba(4, 121, 193, 0.2) 100%
  );
  @media (max-width: ${SM}) {
    grid-column: 3/12;
    padding: 89px 24px 58px;
  }
`;

export const FooterHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${SM}) {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }
  @media (min-width: ${XXXL}) {
    .logo svg {
      width: 80px;
      height: 80px;
    }
  }
`;
export const Container = styled.div`
  width: 84%;
  margin: auto;

  @media (max-width: ${MD}) {
    width: 90%;
  }

  @media (min-width: ${LG}) {
    max-width: 1160px;
  }
  @media (min-width: ${XLGGG}) {
    max-width: 1280px;
  }
  @media (min-width: ${XXXL}) {
    max-width: 1800px;
  }
`;
export const FooterSocialLinks = styled.ul`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  a:visited,
  a:focus,
  a:active {
    outline: none !important;
    background: transparent;
    border: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  @media (max-width: ${SM}) {
    justify-content: start;
    padding-left: 0;
  }
  @media (min-width: ${XXXL}) {
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 50px;
  p {
    color: #000;
    @media (max-width: ${SM}) {
      margin: 0;
    }
    @media (min-width: ${XXXL}) {
      font-size: 1.4rem;
    }
  }
  @media (max-width: ${SM}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 0;

    font-size: 0.875rem;
    line-height: 20px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  column-gap: 16px;

  @media (max-width: ${SM}) {
    margin-bottom: 24px;
  }
`;

export const StyledLink = styled.a`
  color: #000;
  font-size: 0.875rem;
  letter-spacing: -0.14px;
  @media (max-width: ${SM}) {
    text-decoration: underline;
  }
  @media (min-width: ${XXXL}) {
    font-size: 1.4rem;
  }
`;
