import useTranslation from 'next-translate/useTranslation';
import {
  Section,
  StyledRow,
  Title,
  Container,
  ImagesWrapper,
  Header,
  TextWrapper,
} from './contentCreators.styled';
import { ReactSVG } from 'react-svg';

export default function ContentCreators() {
  const { t } = useTranslation('common');

  return (
    <Section>
      <Container>
        <StyledRow>
          <div>
            <TextWrapper>
              <Header>
                <ReactSVG
                  height={30}
                  src="/static/assets/images/landing/brand-logo.svg"
                />
                <span>Reach Plus</span>
              </Header>
              <Title>{t('landing.find-best-content-creators')}</Title>
            </TextWrapper>
            <ImagesWrapper>
              <div>
                <img
                  src="/static/assets/images/landing/findbest1.webp"
                  alt="find best"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="/static/assets/images/landing/findbest2.webp"
                  alt="find best"
                  loading="lazy"
                />
              </div>
            </ImagesWrapper>
          </div>
          <div>
            <ImagesWrapper isRightSection>
              <div className="margin">
                <picture>
                  <source
                    media="(max-width:768px)"
                    srcSet={`/static/assets/images/landing/findbest3mobile.webp`}
                  />
                  <img
                    src={`/static/assets/images/landing/findbest3.webp`}
                    alt="find best"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div>
                <picture>
                  <source
                    media="(max-width:768px)"
                    srcSet={`/static/assets/images/landing/findbest4mobile.webp`}
                  />
                  <img
                    src={`/static/assets/images/landing/findbest4.webp`}
                    alt="find best"
                    loading="lazy"
                  />
                </picture>
              </div>
            </ImagesWrapper>
          </div>
        </StyledRow>
      </Container>
    </Section>
  );
}
