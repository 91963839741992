import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { logPageView } from '@/utils/analytics';
import { loggedIn } from '@/utils/auth';
import useTranslation from 'next-translate/useTranslation';
import ContentCreators from '@/components/LandingPage/contentCreators';
import DefaultLayout from '@/components/DefaultLayout';
import { YourUltimate } from '@/components/LandingPage';
import GetStarted from '@/components/LandingPage/getStarted';
import ShareOffer from '@/components/LandingPage/shareOffer';
import { Container } from '@/components/LandingPage/shareOffer/ShareOffer.styled';

const Landing: React.FC = () => {
  const { push } = useRouter();
  const { t } = useTranslation('common');

  useEffect(() => {
    logPageView(window.location.pathname);
    if (loggedIn()) {
      push('/start');
    }
  }, []);

  return (
    <DefaultLayout>
      <YourUltimate />

      <ContentCreators />
      <GetStarted
        title={t('landing.connect-with-content-creators')}
        desc={t('landing.discover-perfect-influencers')}
        image="/static/assets/images/landing/getstarted1.webp"
      />
      <Container>
        <ShareOffer
          title={t('landing.announcement-customers')}
          desc={t('landing.push-announcemen-to-customers')}
          image="/static/assets/images/landing/shareoffer1.webp"
        />
        <ShareOffer
          title={t('landing.share-offer')}
          desc={t('landing.inform-your-customers')}
          image="/static/assets/images/landing/shareoffer2.webp"
        />
      </Container>
      <GetStarted
        title={t('landing.reporting-analytics')}
        desc={t('landing.review-metrics')}
        image="/static/assets/images/landing/getstarted2.webp"
        isReporting
      />
    </DefaultLayout>
  );
};

export default Landing;
