import { LG, MDB, XLGGG, XMD, XSM, XXXL } from '@/constants';
import styled from 'styled-components';

export const Section = styled.div`
  padding: 5rem 0 0;
  background: url('/static/assets/images/landing/Bg.png') no-repeat center
    center/cover;
  height: fit-content;
  padding: 10rem 0 5rem;

  :lang(ar) {
    background: url('/static/assets/images/landing/Bg-ar.png') no-repeat center
      center/cover;
  }

  @media (max-width: ${MDB}) {
    background: url('/static/assets/images/landing/BG-mobile.png');
    background-size: cover;
    height: fit-content;
    padding: 5rem 0 3rem;
  }
  @media (min-width: ${XXXL}) {
    height: fit-content;
    padding: 15rem 0 6rem;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${XSM}) {
    max-width: 560px;
  }
  @media (min-width: ${MDB}) {
    max-width: 740px;
    padding-right: 10px;
    padding-left: 10px;
  }
  @media (min-width: ${XMD}) {
    max-width: 980px;
  }
  @media (min-width: ${LG}) {
    max-width: 1160px;
  }
  @media (min-width: ${XLGGG}) {
    max-width: 1280px;
  }
  @media (min-width: ${XXXL}) {
    max-width: 1800px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  column-gap: 119px;
  padding: 0 14px;

  .first {
    width: 90%;
    margin-top: 20px;

    @media (max-width: ${MDB}) {
      width: 97%;
    }
  }
  .second {
    width: 110%;
    @media (max-width: ${MDB}) {
      width: 100%;
    }
  }

  @media (max-width: ${LG}) {
    column-gap: 20px;
    padding: 0;
  }
  @media (max-width: ${MDB}) {
    flex-direction: column;
    margin-top: 80px;
    row-gap: 80px;
  }
`;

export const Title = styled.h2`
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: -0.48px;
  margin: 0;

  .first-word {
    color: #0078d4;
  }

  :lang(ar) {
    line-height: 66px;
  }
  @media (max-width: ${XLGGG}) {
    font-size: 2.5rem;
    line-height: 55px;
  }

  @media (max-width: ${LG}) {
    font-size: 1.8rem;
    line-height: 40px;
    :lang(ar) {
      line-height: 40px;
    }
  }
  @media (min-width: ${XXXL}) {
    font-size: 4rem;
    line-height: 74px;
  }
`;

export const Description = styled.p`
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.16px;
  margin: 24px 0;
  @media (max-width: ${LG}) {
    font-size: 0.875rem;
    line-height: normal;
  }
  @media (max-width: ${MDB}) {
    font-size: 1rem;
    line-height: normal;
  }
  @media (min-width: ${XXXL}) {
    font-size: 1.4rem;
    line-height: 27px;
  }
`;

export const ImageContainer = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`;
export const GetStartedButton = styled.button`
  background: none;
  border: none;
  outline: none;

  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.16px;
  text-transform: uppercase;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #0479c1;
    display: flex;
    align-items: center;
  }
  div {
    margin-left: 1px;
    margin-top: 2px;
    &:lang(ar) {
      margin-top: 4px;
    }

    svg {
      transition: transform 0.3s ease;
      transform-origin: center;

      &:lang(ar) {
        transform: rotate(180deg);
      }
      @media (min-width: ${XXXL}) {
        width: 35px;
        height: 35px;
      }
    }
  }

  &:hover div svg {
    transform: translateX(8px);

    &:lang(ar) {
      transform: rotate(180deg) translateX(-8px);
    }
  }
  @media (min-width: ${XXXL}) {
    font-size: 1.5rem;
  }
`;
