import { LG, MD, MDB, XLGGG, XMD, XSM, XXXL } from '@/constants';
import styled from 'styled-components';

export const Section = styled.div<{
  readonly isReporting?: boolean;
}>`
  margin-top: ${({ isReporting }) => (isReporting ? '0' : '150px')};
  @media (max-width: ${MDB}) {
    margin-top: ${({ isReporting }) => (isReporting ? '0' : '80px')};
  }
`;
export const Container = styled.div`
  width: 100%;
  padding: 50px 24px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eaf4fa;
  border-radius: 20px;

  @media (min-width: ${XSM}) {
    max-width: 560px;
  }
  @media (min-width: ${MDB}) {
    max-width: 740px;
    padding: 50px 100px;
  }
  @media (min-width: ${XMD}) {
    max-width: 980px;
  }
  @media (min-width: ${LG}) {
    max-width: 1160px;
  }
  @media (min-width: ${XLGGG}) {
    max-width: 1280px;
  }
  @media (min-width: ${XXXL}) {
    max-width: 1800px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
  .first {
    width: 90%;
    @media (max-width: ${MDB}) {
      width: 100%;
    }
  }
  .second {
    width: 110%;
    @media (max-width: ${MDB}) {
      width: 100%;
    }
  }
  @media (max-width: ${MDB}) {
    flex-direction: column;
    row-gap: 40px;
  }
  @media (min-width: 1535px) {
    column-gap: 100px;
  }
`;

export const Title = styled.h2`
  color: #000;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.33px;
  margin: 0;
  @media (min-width: ${XXXL}) {
    font-size: 2.7rem;
  }
`;

export const Description = styled.p`
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.18px;
  margin: 16px 0 32px;
  @media (min-width: ${MDB}) and (max-width: ${MD}) {
    font-size: 0.875rem;
  }
  @media (min-width: ${XXXL}) {
    font-size: 1.4rem;
  }
`;

export const ImageContainer = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`;
