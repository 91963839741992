import {
  FooterContainer,
  FooterHeaderWrapper,
  FooterLinks,
  FooterSocialLinks,
  FooterWrapper,
  Container,
} from './Footer.styled';
import { ReactSVG } from 'react-svg';
import useTranslation from 'next-translate/useTranslation';
import { StyledLink } from './Footer.styled';
import { REACH_EMAIL } from '@/constants';

const socialLinks = [
  {
    name: 'reach',
    icon: '/static/assets/images/landing/social-icons/rlink.svg',
    uri: 'https://reach.link/reach',
  },
  {
    name: 'instagram',
    icon: '/static/assets/images/landing/social-icons/instagram.svg',
    uri: 'https://www.instagram.com/reach.link',
  },
  {
    name: 'linkedin',
    icon: '/static/assets/images/landing/social-icons/linkedin.svg',
    uri: 'https://www.linkedin.com/company/reach-kw',
  },
  {
    name: 'facebook',
    icon: '/static/assets/images/landing/social-icons/facebook.svg',
    uri: 'https://www.facebook.com/reachnetworks1',
  },
  {
    name: 'mail',
    icon: '/static/assets/images/landing/social-icons/mail.svg',
    uri: `mailto:${REACH_EMAIL}`,
  },
];

export default function Footer() {
  const { t } = useTranslation('common');

  return (
    <FooterContainer>
      <Container>
        <FooterHeaderWrapper>
          <ReactSVG
            src="/static/assets/images/landing/logo.svg"
            title="Reach plus"
            className="logo"
          />

          <FooterSocialLinks>
            {socialLinks.map(socialLink => (
              <a
                key={socialLink.name}
                href={socialLink.uri}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <ReactSVG src={socialLink.icon} title={socialLink.name} />
              </a>
            ))}
          </FooterSocialLinks>
        </FooterHeaderWrapper>

        <FooterWrapper>
          <p>&copy; {new Date().getFullYear()} Reach Network</p>
          <FooterLinks>
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://reach.link/terms-conditions"
            >
              {t('landing.terms-of-use')}
            </StyledLink>
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://reach.link/privacy-policy"
            >
              {t('landing.privacy-policy')}
            </StyledLink>
          </FooterLinks>
        </FooterWrapper>
      </Container>
    </FooterContainer>
  );
}
